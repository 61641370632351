import React from 'react';
import Skeleton from 'react-loading-skeleton';

const PageLoader = () => {
    return (
        <div className='p-8'>
            <Skeleton
                height={800}
            />
        </div>
    );
};

export default PageLoader;