import React, { Suspense, lazy } from 'react';
import Footer from '../components/layouts/Footer';
import Header from '../components/layouts/Header';
import PageLoader from '../components/loaders/PageLoader';
import WhatsappFloat from '../components/layouts/WhatsappFloat';
import { Outlet, ScrollRestoration, createBrowserRouter } from 'react-router-dom';

const HomePage = lazy(() => import('../pages/HomePage'));
const AboutPage = lazy(() => import('../pages/AboutPage'));
const ContactPage = lazy(() => import('../pages/ContactPage'));
const ProductsPage = lazy(() => import('../pages/ProductsPage'));
const ProductPage = lazy(() => import('../pages/ProductPage'));

export const router = createBrowserRouter([
    {
        element:
            <>
                <ScrollRestoration />
                <Header />
                <Outlet />
                <WhatsappFloat />
                <Footer />
            </>,
        children: [
            {
                path: '/',
                element:
                    <Suspense fallback={<PageLoader />}>
                        <HomePage />
                    </Suspense>
            },
            {
                path: '/productos',
                element:
                    <Suspense fallback={<PageLoader />}>
                        <ProductsPage />
                    </Suspense>
            },
            {
                path: '/productos/:product',
                element: 
                <Suspense fallback={<PageLoader />}>
                    <ProductPage />
                </Suspense>
            },
            {
                path: '/nosotros',
                element: 
                <Suspense fallback={<PageLoader />}>
                    <AboutPage />
                </Suspense>
            },
            {
                path: '/contactanos',
                element: 
                <Suspense fallback={<PageLoader />}>
                    <ContactPage />
                </Suspense>
            }
        ]
    }
]);
