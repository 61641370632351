import { RouterProvider } from 'react-router-dom';
import { router } from './routers/AppRouter';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';

// ? Tracking ID provided by Google Analytics
const TRACKING_ID = 'G-KT221Y6K8Y';

function App() {

  ReactGA.initialize(TRACKING_ID);

  return (
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  );
}

export default App;
