import React from 'react';
import ReactGA from 'react-ga4';
import { UilWhatsapp } from '@iconscout/react-unicons';


const WhatsappFloat = () => {

    const sendGAEvent = () => {
        // * Send click event to Google Analytics
        ReactGA.event({
            category: 'Información',
            action: 'Whatsapp clic',
            label: 'Whatsapp abierto desde botón flotante'
        });
    };

    return (
        <div className='wp-float fixed bottom-8 right-8 bg-main-green rounded-full p-2 transition-all duration-100'>
            <a 
                href='https://api.whatsapp.com/send?phone=573015222004&text=Hola%2C%20estaba%20en%20su%20sitio%20web%20y%20vi%20algo%20que%20me%20interesa!' 
                target='_blank'
                rel='noreferrer'
                aria-label='Whatsapp Full Movil'
                onClick={sendGAEvent}
            >
                <UilWhatsapp size={40} color='#fff' />
            </a>
        </div>
    );
};

export default WhatsappFloat;