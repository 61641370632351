import React from 'react';
import TikTokLogo from "./TikTokIcon";
import { API_URL } from '../../api/global';
import { UilPhone } from '@iconscout/react-unicons';
import { UilFacebookF } from '@iconscout/react-unicons';
import { UilInstagram } from '@iconscout/react-unicons';
import { UilMapMarker } from '@iconscout/react-unicons';
import { UilAngleRight } from '@iconscout/react-unicons';

import '../../styles/footer.css';

const Footer = () => {
    return (
        <footer>
            <div className='header flex'>
                <div className='footer_politicas flex flex-col'>
                    <p>
                        <a
                            className='underline cursor-pointer flex items-center gap-x-2'
                            href={`${API_URL}/storage/docs/politica_de_envio.pdf`}
                            target='_blank'
                            rel='noreferrer'
                            aria-label='Política de envío'
                        >
                            Política de envío
                        </a>
                        <a
                            className='underline cursor-pointer flex items-center gap-x-2'
                            href={`${API_URL}/storage/docs/politica_de_garantia.pdf`}
                            target='_blank'
                            rel='noreferrer'
                            aria-label='Política de garantía'
                        >
                            Política de garantía
                        </a>
                    </p>

                    <p>
                        <a
                            className='cursor-pointer text-main-orange font-semibold flex items-center'
                            href='https://www.imeicolombia.com.co/'
                            target='_blank'
                            rel='noreferrer'
                            aria-label='Verifica aquí tu IMEI'
                        >
                            Verifica aquí tu IMEI <UilAngleRight size={25} color='#f3712b' />
                        </a>
                    </p>
                </div>
                <div className='footer_text flex flex-col gap-y-4'>
                    <p>
                        Barranquilla, Colombia
                        <span className='flex justify-center items-center gap-x-2'>
                            <UilMapMarker size={20} color='#eef0f2' />
                            Cll 93 # 43-135 local 18
                        </span>
                        <span className='flex justify-center items-center gap-x-2'>
                            <UilPhone size={20} color='#eef0f2' />
                            Servicio técnico: 301 522 2004
                        </span>
                    </p>
                    <div className='footer_social flex'>
                        <a
                            href='https://m.facebook.com/p/Full-m%C3%B3vil-100081207080645/'
                            target='blank'
                            rel='noreferrer'
                            aria-label='Facebook Full Movil'
                            className='bg-soft-white rounded-full p-1 flex justify-center items-center'
                        >
                            <UilFacebookF size={22} color='#f3712b' />
                        </a>
                        <a
                            href='https://www.instagram.com/full_movil/?hl=es'
                            target='blank'
                            rel='noreferrer'
                            aria-label='Instagram Full Movil'
                            className='bg-soft-white rounded-full p-1 flex justify-center items-center'
                        >
                            <UilInstagram size={22} color='#f3712b' />
                        </a>
                        <a
                            href='https://www.tiktok.com/@full_movil1?_t=8gZ2fVQUNlt&_r=1'
                            target='blank'
                            rel='noreferrer'
                            aria-label='Instagram Full Movil'
                            className='bg-soft-white rounded-full p-1 flex justify-center items-center'
                        >
                            <TikTokLogo size={22} color='#f3712b' />
                        </a>
                    </div>
                </div>
                <div className='footer_map'>
                    <iframe
                        title='Ubicación Full Movil'
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.476626638406!2d-74.83035402626099!3d11.002821454979662!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8ef42c55363d7649%3A0x1d3c0813c076ed73!2sCl.%2093%20%2343-135%2C%20Nte.%20Centro%20Historico%2C%20Barranquilla%2C%20Atl%C3%A1ntico!5e0!3m2!1ses!2sco!4v1696708216290!5m2!1ses!2sco'
                        width={250}
                        height={250}
                        style={{ border: 'none' }}
                        loading='lazy'
                        rel='preconnect'
                        referrerPolicy='no-referrer-when-downgrade'
                    >
                    </iframe>
                </div>
            </div>

            <div className='footer_logos flex'>
                <a
                    href='https://www.mintic.gov.co/portal/inicio/'
                    target='_blank'
                    rel='noreferrer'
                    aria-label='Mintic'
                >
                    <img
                        src='/assets/images/mintic.webp'
                        alt='Mintic'
                        width={120}
                        height={120}
                        loading='lazy'
                    />
                </a>

                <a
                    href='https://www.sic.gov.co/'
                    target='_blank'
                    rel='noreferrer'
                    aria-label='Superintendencia de industria y comercio'
                >
                    <img
                        src='/assets/images/intendencia_industria.webp'
                        alt='Superintendencia de industria y comercio'
                        width={120}
                        height={120}
                        loading='lazy'
                    />
                </a>
            </div>

            <div className='copy'>
                Todos los derechos reservados © Full Movil {new Date().getFullYear()}.
            </div>
        </footer>
    );
};

export default Footer;