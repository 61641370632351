import React, { useState } from 'react';
import TikTokLogo from './TikTokIcon';
import { Link, useLocation } from 'react-router-dom';
import { UilBars } from '@iconscout/react-unicons';
import { UilFacebookF } from '@iconscout/react-unicons';
import { UilInstagram } from '@iconscout/react-unicons';

import '../../styles/header.css';

const menuItems = [
    { name: 'Inicio', url: '' },
    { name: 'Productos', url: 'productos' },
    { name: 'Nosotros', url: 'nosotros' },
    { name: 'Contacto', url: 'contactanos' }
];

const Header = () => {

    const { pathname } = useLocation();

    const [openMenu, setOpenMenu] = useState(false);

    return (
        <header className="flex justify-between items-center">
            <button
                className="menu_btn"
                aria-label='Menú'
                onClick={() => setOpenMenu(true)}
            >
                <UilBars size={35} color='#f3712b' />
            </button>
            <Link to='/' className="logo">
                <img
                    src='/assets/images/logo.webp'
                    alt="Full movil | Barranquilla"
                    width={100}
                    height={100}
                    loading='lazy'
                />
            </Link>
            <nav
                className='nav_bar'
                style={{ display: openMenu ? 'block' : 'none' }}
            >
                <ul>
                    {
                        menuItems.map((item, index) => (
                            <li
                                key={index}
                                className={`${pathname === '/' + item.url ? 'active_link' : ''} font-medium uppercase transition-all duration-100`}
                                onClick={() => setOpenMenu(false)}
                            >
                                <Link to={`/${item.url}`}>
                                    {item.name}
                                </Link>
                            </li>
                        ))
                    }
                    <li
                        className='close_menu uppercase font-medium text-center'
                        onClick={() => setOpenMenu(false)}
                    >
                        Cerrar
                    </li>
                </ul>
            </nav>
            <div className='flex gap-2'>
                <a
                    href='https://m.facebook.com/p/Full-m%C3%B3vil-100081207080645/'
                    target='blank'
                    rel='noreferrer'
                    aria-label='Facebook Full Movil'
                    className='btn_gradient rounded-full p-1 flex justify-center items-center'
                >
                    <UilFacebookF size={22} color='#fff' />
                </a>
                <a
                    href='https://www.instagram.com/full_movil/?hl=es'
                    target='blank'
                    rel='noreferrer'
                    aria-label='Instagram Full Movil'
                    className='btn_gradient rounded-full p-1 flex justify-center items-center'
                >
                    <UilInstagram size={22} color='#fff' />
                </a>
                <a
                    href='https://www.tiktok.com/@full_movil1?_t=8gZ2fVQUNlt&_r=1'
                    target='blank'
                    rel='noreferrer'
                    aria-label='Instagram Full Movil'
                    className='btn_gradient rounded-full p-1 flex justify-center items-center'
                >
                    <TikTokLogo size={22} color='#fff' />
                </a>
            </div>
        </header>
    );
};

export default Header;
